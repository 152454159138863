.level0.wChild.open .wChild {
  position: relative;
  overflow: visible;
}

.container {
  width: 100%;
  padding: 0 3rem;
}

.nav-panel {
  background-color: white;
  border-top: 1px solid black;
  color: #1a1a1a;
  left: 0;
  margin: 0 auto;
  min-height: 400px;
  max-width: 80%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  transition: visibility .25s linear, opacity .25s ease-in;
  visibility: hidden;
  width: 80%;
  z-index: 99;
}

@media screen and (min-width: 1920px) {
  .nav-panel {
    width: 55%;
  }
}

.navDesktop .nav-panel__inner {
  background-color: white;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 1.5rem 1.5rem 4rem 1.5rem;
  width: 100%;
}

.level0.open .nav-panel,
.level0:focus .nav-panel {
  opacity: 1;
  visibility: visible;
}

.nav-panel img {
  display: block;
  width: 100%;
}

.nav-links {
  flex: 1;
  display: flex;
  padding: 1rem;
  justify-content: center;
}

.nav-links>ul {
  flex: 1;
  padding: 0 1rem;
}

.nav-links>ul:last-of-type {
  border: 0;
}

.section-header a {
  display: block;
}

.nav-links ul li.section-header:first-child {
  margin-top: 0;
}

.section-break {
  margin-bottom: 1rem;
}

.nav-promotions {
  align-self: center;
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 660px;
  padding: 1rem;
  overflow: hidden;
}

.nav-promotion {
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
  margin: 0 1rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .nav-promotion {
    flex: 0 0 25%;
  }
}

.nav-promotion:last-of-type {
  margin-bottom: 0;
}

.nav-promotion__img-container {
  background-color: #f3f3f3;
  position: relative;
  padding-bottom: 162%;
  height: 0;
  opacity: 1;
}

.nav-promotion__img-container:hover {
  opacity: .8;
}

.nav-promotion__img {
  height: 100%;
  left: 0;
  position: absolute;
  object-fit: cover;
  top: 0;
  width: 100%;
}

.nav-promotion__text-container {
  text-align: center;
}

.nav-promotion__title {
  margin: 1rem 0 0;
  padding: 0;
  color: #505050;
  font-size: .8rem;
  font-weight: 600;
  transition: font-size .5s;
}

.nav-promotion__title:hover {
  color: black;
}

.nav-promotion__cta {
  margin: .5rem 0 0;
  padding: 0 0 .25rem;
  border-bottom: 2px solid black;
  line-height: initial;
  font-size: .75rem;
  color: #505050;
  display: inline-block;
  transition: font-size .5s;
}

.nav-promotion__cta:hover {
  border-color: black;
  color: black;
}

@media only screen and (min-width: 1025px) {
  .nav-promotion__title {
    font-size: .95rem
  }
  .nav-promotion__cta {
    border-color: transparent;
    font-size: 1rem;
  }
}

.nav-open::after {
  position: fixed;
  opacity: 1;
}

.nav-panel .lazy {
  opacity: 0;
}

.nav-panel .lazy.loaded,
.nav-panel .lazy.lazy-loaded,
.nav-panel .lazy.loading {
  opacity: 1;
}

.navTabletBG.show {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(25, 25, 25, .75);
  transition: background-color .5s;
}

.space-above {
  margin-top: 3.45rem;
}
