/* ************************************************************************************************ */

/* [NOTE]: Any selectors which include an ID are used to override MESH styles */

/* ************************************************************************************************ */

#dropNav #nav-menu {
  position: inherit;
  max-width: 59.5%;
  white-space: nowrap;
  height: auto;
  display: flex;
  float: right;
  overflow: hidden;
  transition: max-width .5s;
}

#dropNav #nav-menu li a {
  font-size: .875rem;
  transition: font-size .5s;
}

@media only screen and (min-width: 1210px) {
  #dropNav #nav-menu {
    max-width: 52.5%;
    font-size: .75rem;
  }
}

@media only screen and (min-width: 1330px) {
  #dropNav #nav-menu {
    max-width: 100%;
    overflow: hidden;
  }
  #dropNav #nav-menu li a {
    font-size: .92rem;
  }
}

#dropNav #nav-menu .nav-panel {
  white-space: normal;
}

#nav-menu .level0 a {
  color: #666;
  text-align: center;
}

#nav-menu .level0 a:hover {
  background-color: black;
  color: white;
}

#nav-menu .level0 .wChild:hover,
#nav-menu .level0.open .wChild {
  background-color: #B5B5B5;
  color: white;
}

/* Override */

#dropNav .maxWidth>ul>li.wChild>a:before {
  color: black;
}

/* Override */

#dropNav .maxWidth>ul>li.navigation-sale-item>a:before {
  color: white;
}

#nav-menu .no-menu a:hover {
  background: #1a1a1a;
  color: #FFF;
}

#nav-menu .nav-panel a:hover {
  background-color: transparent;
  color: black;
}

#nav-menu .level0:nth-child(3) .nav-links,
#nav-menu .level0:nth-child(4) .nav-links,
#nav-menu .level0:nth-child(5) .nav-links {
  flex: .5;
}

@media only screen and (min-width: 1025px) {
  #nav-menu .level0:nth-child(3) .nav-links,
  #nav-menu .level0:nth-child(4) .nav-links,
  #nav-menu .level0:nth-child(5) .nav-links {
    flex: .3;
  }
}

#nav-menu .nav-links .section-header,
#nav-menu .nav-links ul li {
  display: flex;
  justify-content: center;
  text-align: center;
}

#nav-menu .nav-links ul li a {
  flex: 1;
  text-align: center;
}

#dropNav #nav-menu .nav-links a {
  color: #000;
  display: inline-block;
  font-size: .6rem;
  margin-bottom: .75rem;
  white-space: nowrap;
}

@media only screen and (min-width: 1025px) {
  #dropNav #nav-menu .nav-links a {
    font-size: .75rem;
    color: #505050;
  }
}

#dropNav #nav-menu .nav-links p {
  margin: 0;
}

#dropNav #nav-menu .nav-links a:hover,
#dropNav #nav-menu .nav-links p:hover {
  color: #000;
}

@media screen and (min-width: 1366px) {
  #dropNav #nav-menu .nav-links a {
    font-size: 12px;
  }
}

#dropNav .section-header,
#dropNav #nav-menu .nav-panel .section-header a {
  font-size: .7rem;
  font-weight: 600;
  margin: 1rem 0;
  padding-bottom: .25rem;
  position: relative;
  text-transform: uppercase;
}

#dropNav #nav-menu .nav-panel .section-header a {
  color: #505050;
}

#dropNav #nav-menu .nav-panel .section-header a:hover {
  color: black;
}

@media only screen and (min-width: 1025px) {
  #dropNav .section-header,
  #dropNav #nav-menu .nav-panel .section-header a {
    font-size: .85rem;
  }
}

#dropNav #nav-menu .bold {
  display: inline-flex;
  left: 50%;
  transform: translateX(-50%);
}

#dropNav #nav-menu .bold a {
  font-weight: 600;
  font-size: .8rem;
}

#dropNav #nav-menu .bold:hover a {
  border-bottom: 2px solid black;
  padding-bottom: .5rem;
}

.maxWidth #searchButton {
  font-size: .75rem;
  left: 256px;
  transition: width .5s, font-size .5s, left .5s;
  width: 9.25rem;
}

@media only screen and (min-width: 1220px) {
  /* Default MESH styles */
  .maxWidth #searchButton {
    font-size: 16px;
    left: 280px;
    width: 300px;
  }
}
