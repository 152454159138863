#nav-menu .linkNewin .nav-links {
  flex: .25;
}

@media only screen and (min-width: 1025px) {
  .linkNewin .nav-panel {
    max-width: 60%;
  }
}

@media only screen and (min-width: 1920px) {
  .linkNewin .nav-panel {
    max-width: 40%;
  }
}

.linkNewin .nav-links>ul {
  justify-content: center;
  flex: 0;
}
