#nav-menu .navigation-sale-item .wChild {
  color: #de3231;
}

#nav-menu .navigation-sale-item .wChild:hover,
#nav-menu .navigation-sale-item.open .wChild {
  background-color: #de3231;
  color: white;
}

#nav-menu .navigation-sale-item .nav-links {
  flex: 1.25;
}

#nav-menu .level0.navigation-sale-item .wChild::after {
  border-top: 1.25rem solid #de3231;
}
